import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-kent-washington.png'
import image0 from "../../images/cities/scale-model-of-soos-creek-botanical-garden-in-kent-washington.png"
import image1 from "../../images/cities/scale-model-of-mast-center-aquarium-in-kent-washington.png"
import image2 from "../../images/cities/scale-model-of-seattle-ballooning-in-kent-washington.png"
import image3 from "../../images/cities/scale-model-of-seattle-chocolate-in-kent-washington.png"
import image4 from "../../images/cities/scale-model-of-angle-lake-park-in-kent-washington.png"
import image5 from "../../images/cities/scale-model-of-steel-lake-park-in-kent-washington.png"
import image6 from "../../images/cities/scale-model-of-seattle-southside-regional-tourism-authority-in-kent-washington.png"
import image7 from "../../images/cities/scale-model-of-auburn-tourism-board-in-kent-washington.png"
import image8 from "../../images/cities/scale-model-of-explore-auburn-wa-in-kent-washington.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Kent'
            state='Washington'
            image={image}
            lat='47.388'
            lon='-122.2127'
            attractions={ [{"name": "Soos Creek Botanical Garden", "vicinity": "29308 132nd Ave SE, Auburn", "types": ["point_of_interest", "establishment"], "lat": 47.33945809999999, "lng": -122.16345810000001}, {"name": "MaST Center Aquarium", "vicinity": "28203 Redondo Beach Dr S, Des Moines", "types": ["aquarium", "point_of_interest", "establishment"], "lat": 47.348869, "lng": -122.32439399999998}, {"name": "Seattle Ballooning", "vicinity": "16247 8th Ave SW, Burien", "types": ["travel_agency", "university", "point_of_interest", "establishment"], "lat": 47.4565031, "lng": -122.34509300000002}, {"name": "Seattle Chocolate", "vicinity": "1180 Andover Park W, Seattle", "types": ["food", "store", "point_of_interest", "establishment"], "lat": 47.44284409999999, "lng": -122.25422320000001}, {"name": "Angle Lake Park", "vicinity": "19408 International Blvd, SeaTac", "types": ["park", "point_of_interest", "establishment"], "lat": 47.4280785, "lng": -122.29405659999998}, {"name": "Steel Lake Park", "vicinity": "2410 S 312th St, Federal Way", "types": ["park", "point_of_interest", "establishment"], "lat": 47.3246179, "lng": -122.30115649999999}, {"name": "Seattle Southside Regional Tourism Authority", "vicinity": "3100 S 176th St, SeaTac", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 47.4456195, "lng": -122.29293230000002}, {"name": "Auburn Tourism Board", "vicinity": "25 W Main St, Auburn", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 47.3077398, "lng": -122.23063239999999}, {"name": "Explore Auburn WA", "vicinity": "25 W Main St, Auburn", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 47.3077227, "lng": -122.23068619999998}] }
            attractionImages={ {"Soos Creek Botanical Garden":image0,"MaST Center Aquarium":image1,"Seattle Ballooning":image2,"Seattle Chocolate":image3,"Angle Lake Park":image4,"Steel Lake Park":image5,"Seattle Southside Regional Tourism Authority":image6,"Auburn Tourism Board":image7,"Explore Auburn WA":image8,} }
       />);
  }
}